@use '@angular/material' as mat;

/* TODO(mdc-migration): Remove legacy-core once all legacy components are migrated */
@include mat.elevation-classes();
@include mat.app-background();

$light-primary-text: white;

/* For use in src/lib/core/theming/_palette.scss */
$mat-blue-primary: (
  50: #e5ebee,
  100: #beced5,
  200: #93aeb9,
  300: #688d9d,
  400: #477488,
  500: #275c73,
  600: #23546b,
  700: #1d4a60,
  800: #174156,
  900: #0e3043,
  A100: #7cccff,
  A200: #49b8ff,
  A400: #16a4ff,
  A700: #0099fc,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #ffffff
  )
);

$mat-blue-grey: map-merge(
  mat.$m2-blue-grey-palette,
  (
    contrast: (
      300: $light-primary-text
    )
  )
);

$app-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: mat.m2-define-palette($mat-blue-primary),
      accent: mat.m2-define-palette($mat-blue-grey, 300, 100, 500),
      warn: mat.m2-define-palette(mat.$m2-red-palette)
    )
  )
);

/* TODO(mdc-migration): Remove all-legacy-component-themes once all legacy components are migrated */
@include mat.all-component-themes($app-theme);
